@import '~styles/base';

.wrapper {
  @include size(435px, auto);
  @include flex($align: center, $justify: space-between);

  @include media-breakpoint-down (md) {
    @include size(90vw, auto);
    @include flex($align: center, $justify: center);

    position: relative;
  }

  .placeholder {
    width: 74px;

    @include media-breakpoint-down (md) {
      display: none;
    }
  }

  .container {
    @include size(434px, auto);
    @include border-radius(12px);
    @include relative();
    @include flex($direction: column, $align: center);
  
    margin-bottom: 14px;
    padding-bottom: 10px;
    background-color: $snow;
    transition: box-shadow .2s ease-in-out;
    box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.15);

    &:hover {
      cursor: pointer;
      box-shadow: 0px 13px 87px 0 rgba(0, 0, 0, 0.15);
    }

    .name {
      @include font(h28Med, $color-gray-darker, $align: center, $line-height: 33px);
      @include flex($align: center);
  
      margin: 30px 16px;
    }

    .buttonsWrapper {
      @include size(418px, 76px);
      @include flex($justify: space-around, $align: center);

      margin: 10px 0;
      margin-top: 30px;

      @include media-breakpoint-down (md) {
        @include size(-webkit-fill-available, 76px);

        .button {
          flex: 1;
        }
      }

      .button {
        @include flex($direction: column, $justify: center, $align: center);

        flex-grow: 1;
        width: auto;
        height: 66px;
        margin: 0 5px;
        transition: all .3s ease-in-out;
        background: rgba(32, 150, 224, 0.08);
        border-radius: 10px;

        img {
          width: 26px;
          height: 26px;
        }
    
        .label {
          @include font(h13Med, #2096E0);

          margin-top: 2px;
          text-align: center;
          letter-spacing: 0.04em;

          @include media-breakpoint-down (md) {
            @include font(h10Reg, #2096E0);
          }
        }
    
        &:hover {
          transform: scale(1.02);
          box-shadow: 0px 10px 47px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.animOut {
  pointer-events: none;
  animation: anim .65s ease-out;
  opacity: 0;
  height: 0;
}

@keyframes anim {
  0% {
    opacity: 1;
    height: 100%;
  }
  30% {
    opacity: 1;
    height: 100%;
  }
  80% {
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.rightCardAnim {
  animation: rightCard .55s ease-out;
}

@keyframes rightCard {
  30% {
    left: 0;
  }
  100% {
    left: 100px;
  }
}

.leftCardAnim {
  animation: leftCard .55s ease-out;
}

@keyframes leftCard {
  30% {
    right: 0;
  }
  100% {
    right: 100px;
  }
}

.dotOutAnim {
  animation: dotOut .15s ease-out;
  transform: scale(0);
  opacity: 0;
}

@keyframes dotOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}

.match {
  @include font(h27, $green);

  line-height: 28px;
  margin: 10px 0 16px 0;
}

.red {
  color: $redRoman
}

.green {
  color: $greenForest
}
